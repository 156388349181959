import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import classNames from 'classnames';
import { useCurrentLang } from 'hooks/useCurrentLang';
import { useScreenService } from 'hooks/useScreenService';

import { Container, Layout } from 'layout';
import Image from 'common/Image';
import Typography from 'common/Typography';
import Button, { BUTTON } from 'components/Button';

import { NotFoundQueryResult } from './model';

import 'styles/main.scss';
import './404.scss';

const NotFoundPage = () => {
  const {
    currentLang: [currentLangCode],
  } = useCurrentLang();
  const { isMobile } = useScreenService();

  const {
    allPageNotFound: { nodes },
    pageNotFound,
  }: NotFoundQueryResult = useStaticQuery(graphql`
    {
      allPageNotFound {
        nodes {
          lang
          text
          tag
          heading
          text
          button {
            ...ButtonFragment
          }
          insectImages {
            image {
              ...TransparentImageFragment
            }
          }
        }
      }
      pageNotFound {
        tag
        heading
        text
        button {
          ...ButtonFragment
        }
        insectImages {
          image {
            ...TransparentImageFragment
          }
        }
      }
    }
  `);

  const {
    tag,
    heading,
    text,
    button: {
      buttonText,
      buttonLink: { url },
      ariaLabel,
    },
    insectImages,
  } = nodes.find((item) => item.lang === currentLangCode) || pageNotFound;

  const tagClassnames = classNames('not-found__tag-block', {
    'not-found__tag-block--black': !isMobile,
  });

  const renderInsects = insectImages
    ? insectImages?.map(({ image }) => (
        <Image
          key={image.altText}
          imageData={image}
          alt={image.altText}
          className="not-found__insect"
        />
      ))
    : null;

  return (
    <Layout headerTransparent>
      <Container noPadding fluid className="not-found">
        <Container className="not-found__wrapper">
          {renderInsects}
          <div className="not-found__content">
            <div className={tagClassnames}>
              <Typography variant="heading3" weight="medium" customClass="not-found__tag">
                {tag}
              </Typography>
            </div>
            <Typography variant="heading1" weight="medium" customClass="not-found__heading">
              {heading}
            </Typography>
            <Typography variant="paragraph1" weight="regular" customClass="not-found__text">
              {text}
            </Typography>
            <Button link={url} variant={BUTTON.VARIANT.CONTAINED} ariaLabel={ariaLabel} icon>
              {buttonText}
            </Button>
          </div>
        </Container>
      </Container>
    </Layout>
  );
};

export default NotFoundPage;
